import styled from '@emotion/styled';

import { CarnivalLayout } from '../CarnivalLayout';
import { PAGE_LAYOUT_HEADER_HEIGHT } from '../PageLayout';

// Container

export const Container = styled(CarnivalLayout)`
  ${p => p.theme.breakpoints.down('sm')} {
    --carnival-layout-padding-horizontal: 0;
  }
`;

// Centered

export const Centered = styled.div`
  min-height: calc(
    100vh - ${PAGE_LAYOUT_HEADER_HEIGHT} - (var(--carnival-layout-padding-vertical) * 2)
  );
`;

// Content

export const Content = styled(CarnivalLayout.Content)`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
`;

// Sidebar

export const Sidebar = styled(CarnivalLayout.Sidebar)``;
