import { EVENT_STATUSES } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { EventFilter, SchoolConfigurationKeyFilter } from 'shared/graphql';

interface Options {
  initial?: EventFilter;

  search?: string;
  status?: keyof typeof EVENT_STATUSES;

  /**
   * Filter the events by "school".
   * @ticket https://github.com/jebelapp/jebel/issues/1569
   */
  school?: SchoolConfigurationKeyFilter;

  /**
   * Filter the events by "status".
   * @default true
   */
  onlyActive?: boolean;
}

export function createEventFilter(options: Options) {
  const filter = createFilterBuilder<EventFilter>(options.initial);

  const onlyActive = options.onlyActive ?? true;

  if (onlyActive && !options.status) {
    filter.and({ status: { equals: EVENT_STATUSES.active } });
  }

  if (options.status) {
    filter.and({ status: { equals: options.status } });
  }

  if (options.school) {
    filter.and({ school: { id: { equals: options.school.id } } });
  }

  if (options.search) {
    const searchFilter = createFilterBuilder<EventFilter>();

    searchFilter.or({ title: { contains: options.search } });
    searchFilter.or({ description: { contains: options.search } });
    searchFilter.or({ otherSponsors: { some: { name: { contains: options.search } } } });
    searchFilter.or({ titleSponsor: { name: { contains: options.search } } });

    filter.and(searchFilter);
  }

  return filter.build();
}
